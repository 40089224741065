import { createInit } from '@main/core/bss-connector/js/init-oa.js';
import { createRenderer } from '@main/core/bss-connector/js/render.js';
/**
 * Created by Marek.Bogdanovic on 14.03.2018.
 * AMD Online Approval initialization for QA - example run npm run dev:amd
 *
 * DON'T FORGET COPY init() & render() to config.js after modify.
 * Modify markets independent: PROD-SE
 *
 */
import { BSSController as BSSConnector } from './controller';
var sim = {
    BSSController: null,
    containerSelector: null,
    externalRightSelector: null,
    externalOverlaySelector: null,
    externalNavSelector: null,
    resolutionsConfig: {
        smMin: 1024,
        mdMin: 1200,
        lgMin: 1400,
    },
    market: 'gb',
    language: 'en',
    version: process.env.ENV_NAME,
    ORP: false,
    isadditionalConfigSet: true,
    additionalConfig: {
        overlay: {
            externalOnDesktopSelector: null,
            showNavigation: true,
        },
    },
    elementType: 'onlineApproval',
    init: init,
    render: render
};
//expose a render method to the outside world
export var renderer = { render: sim.render, css: '' };
function render(options) {
    createRenderer().call(sim, options);
}
function init(options) {
    createInit({ BSSConnector: BSSConnector }).call(sim, options);
}
