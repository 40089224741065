export function gbStep6(_a) {
    var applicationHost = _a.applicationHost, defaultProductId = _a.defaultProductId;
    return [
        {
            componentId: 'componentPersonalInformation_' + defaultProductId,
            testId: 'componentPersonalInformation',
            componentType: 'TemplateComponent',
            componentTemplate: 'componentContainer',
            containerId: 'navigationTabsOnlineApproval_tab-content-5',
            level: 7,
            order: 2,
            productId: [defaultProductId, 'general'],
            isParentDependentChild: true,
            class: 'bss-component-container-wrapper--step-navigation bss-mb-5',
        },
        {
            componentId: 'gridPersonalInformationHeader_' + defaultProductId,
            testId: 'gridPersonalInformationHeader',
            componentType: 'TemplateComponent',
            containerId: 'componentPersonalInformation_' + defaultProductId + '_content',
            productId: [defaultProductId, 'general'],
            level: 8,
            order: 3,
            componentTemplate: 'grid',
            columns: ['bss-col-xs-8', 'bss-col-xs-4'],
        },
        {
            componentId: 'summaryPersonal_' + defaultProductId,
            testId: 'summaryPersonal',
            containerId: 'gridPersonalInformationHeader_' + defaultProductId + '_col-0',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 1,
            text: "<value data-text='general.personal_details'></value>",
            class: 'bss-label--step-navigation-title bss-m-0',
        },
        {
            componentId: 'backToFirstStepViewButton',
            testId: 'backToFirstStepViewButton',
            class: 'bss-button_btn--close bss-button_btn--edit bss-link-wrapper--uppercase bss-link-wrapper--no-sides bss-link-wrapper--pulled-right',
            componentType: 'ButtonComponent',
            componentTemplate: 'link',
            containerId: 'gridPersonalInformationHeader_' + defaultProductId + '_col-1',
            isPublicEvent: false,
            level: 9,
            onClickEvent: 'bss-step-navigation-goToStep-0-navigationTabsOnlineApproval',
            text: "<value data-text='general.back_to_edit'></value>",
            order: 2,
            productId: [defaultProductId, 'general'],
            sendEventToServer: false,
        },
        {
            componentId: 'gridPersonalInformation_' + defaultProductId,
            testId: 'gridPersonalInformation',
            componentType: 'TemplateComponent',
            containerId: 'componentPersonalInformation_' + defaultProductId + '_content',
            productId: [defaultProductId, 'general'],
            level: 8,
            order: 4,
            componentTemplate: 'grid',
            columns: [
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
            ],
        },
        {
            componentId: 'gridPersonalInformationLabel1_' + defaultProductId,
            testId: 'gridPersonalInformationLabel1',
            containerId: 'gridPersonalInformation_' + defaultProductId + '_col-0',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 1,
            text: "<value data-text='general.title'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.dropdownComponents.title.value'></value>",
            class: 'bss-label--step-navigation-description bss-label--step-navigation-description-bordered bss-label--step-navigation-table',
        },
        {
            componentId: 'gridPersonalInformationLabel2_' + defaultProductId,
            testId: 'gridPersonalInformationLabel2',
            containerId: 'gridPersonalInformation_' + defaultProductId + '_col-1',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 2,
            text: "<value data-text='general.firstName'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.firstName.value'></value>",
            class: 'bss-label--step-navigation-description bss-label--step-navigation-description-bordered bss-label--step-navigation-table',
        },
        {
            componentId: 'gridPersonalInformationLabel3_' + defaultProductId,
            testId: 'gridPersonalInformationLabel3',
            containerId: 'gridPersonalInformation_' + defaultProductId + '_col-2',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 3,
            text: "<value data-text='general.lastName'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.lastName.value'></value>",
            class: 'bss-label--step-navigation-description bss-label--step-navigation-description-bordered bss-label--step-navigation-table',
        },
        {
            componentId: 'gridPersonalInformationLabel4_' + defaultProductId,
            testId: 'gridPersonalInformationLabel4',
            containerId: 'gridPersonalInformation_' + defaultProductId + '_col-3',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 4,
            text: "<value data-text='general.phoneNumber'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.phoneNumber.value'></value>",
            class: 'bss-label--step-navigation-description bss-label--step-navigation-description-bordered bss-label--step-navigation-table',
        },
        {
            componentId: 'gridPersonalInformationLabel5_' + defaultProductId,
            testId: 'gridPersonalInformationLabel5',
            containerId: 'gridPersonalInformation_' + defaultProductId + '_col-4',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 5,
            text: "<value data-text='general.email'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.email.value'></value>",
            class: 'bss-label--step-navigation-description bss-label--step-navigation-description-bordered bss-label--step-navigation-table',
        },
        {
            componentId: 'gridPersonalInformationLabel6_' + defaultProductId,
            testId: 'gridPersonalInformationLabel6',
            containerId: 'gridPersonalInformation_' + defaultProductId + '_col-5',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 6,
            text: "<value data-text='general.birthdayDate'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.birthdayDate.value'></value>",
            class: 'bss-label--step-navigation-description bss-label--step-navigation-description-bordered bss-label--step-navigation-table',
        },
        {
            componentId: 'gridPersonalInformationLabel7_' + defaultProductId,
            testId: 'gridPersonalInformationLabel7',
            containerId: 'gridPersonalInformation_' + defaultProductId + '_col-6',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 7,
            text: "<value data-text='general.gender'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.radioButtonComponents.gender.value'></value>",
            class: 'bss-label--step-navigation-description bss-label--step-navigation-description-bordered bss-label--step-navigation-table',
        },
        {
            componentId: 'gridPersonalInformationLabel8_' + defaultProductId,
            testId: 'gridPersonalInformationLabel8',
            containerId: 'gridPersonalInformation_' + defaultProductId + '_col-7',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 8,
            text: "<value data-text='general.maritialStatus'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.dropdownComponents.maritialStatus.value'></value>",
            class: 'bss-label--step-navigation-description bss-label--step-navigation-description-bordered bss-label--step-navigation-table',
        },
        {
            componentId: 'gridPersonalInformationLabel9_' + defaultProductId,
            testId: 'gridPersonalInformationLabel9',
            containerId: 'gridPersonalInformation_' + defaultProductId + '_col-8',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 9,
            text: "<value data-text='general.residentialStatus'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.dropdownComponents.residentialStatus.value'></value>",
            class: 'bss-label--step-navigation-description bss-label--step-navigation-description-bordered bss-label--step-navigation-table',
        },
        {
            componentId: 'componentAddressDetailInformation_' + defaultProductId,
            testId: 'componentAddressDetailInformation',
            componentType: 'TemplateComponent',
            componentTemplate: 'componentContainer',
            containerId: 'navigationTabsOnlineApproval_tab-content-5',
            level: 7,
            order: 3,
            productId: [defaultProductId, 'general'],
            isParentDependentChild: true,
            class: 'bss-component-container-wrapper--step-navigation bss-mb-5',
        },
        {
            componentId: 'gridCurrentAddressDetailsHeader_' + defaultProductId,
            testId: 'gridCurrentAddressDetailsHeader',
            componentType: 'TemplateComponent',
            containerId: 'componentAddressDetailInformation_' + defaultProductId + '_content',
            productId: [defaultProductId, 'general'],
            level: 7,
            order: 4,
            componentTemplate: 'grid',
            columns: ['bss-col-xs-8', 'bss-col-xs-4'],
        },
        {
            componentId: 'summaryAddressDetails_' + defaultProductId,
            testId: 'summaryAddressDetails',
            containerId: 'gridCurrentAddressDetailsHeader_' + defaultProductId + '_col-0',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 8,
            order: 1,
            text: "<value data-text='general.current_address_details'></value>",
            class: 'bss-mb-2 bss-label--step-navigation-small-title',
        },
        {
            componentId: 'backAddressDetailsViewButton_' + defaultProductId,
            testId: 'backAddressDetailsViewButton',
            class: 'bss-button_btn--close bss-button_btn--edit bss-link-wrapper--uppercase bss-link-wrapper--no-sides bss-link-wrapper--pulled-right',
            componentType: 'ButtonComponent',
            componentTemplate: 'link',
            containerId: 'gridCurrentAddressDetailsHeader_' + defaultProductId + '_col-1',
            isPublicEvent: false,
            level: 8,
            onClickEvent: 'bss-step-navigation-goToStep-1-navigationTabsOnlineApproval',
            text: "<value data-text='general.back_to_edit'></value>",
            order: 2,
            productId: [defaultProductId, 'general'],
            sendEventToServer: false,
        },
        {
            componentId: 'gridCurrentAddressDetails_' + defaultProductId,
            testId: 'gridCurrentAddressDetails',
            componentType: 'TemplateComponent',
            containerId: 'componentAddressDetailInformation_' + defaultProductId + '_content',
            productId: [defaultProductId, 'general'],
            level: 8,
            order: 3,
            componentTemplate: 'grid',
            columns: [
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
            ],
        },
        {
            componentId: 'gridCurrentAddressDetailsLabel2_' + defaultProductId,
            testId: 'gridCurrentAddressDetailsLabel2',
            containerId: 'gridCurrentAddressDetails_' + defaultProductId + '_col-0',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 1,
            text: "<value data-text='general.address1'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.address1AddressLine1.value'></value>",
            class: 'bss-label--step-navigation-description bss-label--step-navigation-description-bordered bss-label--step-navigation-table',
        },
        {
            componentId: 'gridCurrentAddressDetailsLabel1_' + defaultProductId,
            testId: 'gridCurrentAddressDetailsLabel1',
            containerId: 'gridCurrentAddressDetails_' + defaultProductId + '_col-1',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 2,
            text: "<value data-text='general.address2'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.address1AddressLine2.value'></value>",
            class: 'bss-label--step-navigation-description bss-label--step-navigation-description-bordered bss-label--step-navigation-table',
        },
        {
            componentId: 'gridCurrentAddressDetailsLabel3_' + defaultProductId,
            testId: 'gridCurrentAddressDetailsLabel3',
            containerId: 'gridCurrentAddressDetails_' + defaultProductId + '_col-2',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 3,
            text: "<value data-text='general.postcode'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.postcode.value'></value>",
            class: 'bss-label--step-navigation-description bss-label--step-navigation-description-bordered bss-label--step-navigation-table',
        },
        {
            componentId: 'gridCurrentAddressDetailsLabel4_' + defaultProductId,
            testId: 'gridCurrentAddressDetailsLabel4',
            containerId: 'gridCurrentAddressDetails_' + defaultProductId + '_col-3',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 4,
            text: "<value data-text='general.city'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.city.value'></value>",
            class: 'bss-label--step-navigation-description bss-label--step-navigation-description-bordered bss-label--step-navigation-table',
        },
        {
            componentId: 'gridCurrentAddressDetailsLabel5_' + defaultProductId,
            testId: 'gridCurrentAddressDetailsLabel5',
            containerId: 'gridCurrentAddressDetails_' + defaultProductId + '_col-4',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 5,
            text: "<value data-text='general.county'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.county.value'></value>",
            class: 'bss-label--step-navigation-description bss-label--step-navigation-description-bordered bss-label--step-navigation-table',
            watcher: [
                {
                    boundDataOperand: 'operandLeft',
                    checkFlat: true,
                    effectSuccess: 'show',
                    effectFail: 'hide',
                    operandLeft: defaultProductId + '.components_textInputComponents_county_value',
                    operator: 'is',
                },
            ],
        },
        {
            componentId: 'gridCurrentAddressDetailsLabel6_' + defaultProductId,
            testId: 'gridCurrentAddressDetailsLabel6',
            containerId: 'gridCurrentAddressDetails_' + defaultProductId + '_col-5',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 6,
            text: "<value data-value='" +
                defaultProductId +
                ".components.labelComponents.address_1_occupation_duration.value'></value>",
            class: 'bss-label--step-navigation-description bss-label--step-navigation-description-bordered bss-label--step-navigation-table bss-label--step-navigation-bottom-info',
            watcher: [
                {
                    boundDataOperand: 'operandLeft',
                    checkFlat: true,
                    effectSuccess: 'show',
                    effectFail: 'hide',
                    operandLeft: defaultProductId +
                        '.components_labelComponents_address_1_occupation_duration_value',
                    operator: 'is',
                },
            ],
        },
        {
            class: 'bss-button_btn--show-hidden bss-hide-hidden-address bss-button-wrapper--pulled-left bss-button_btn--secondary bss-mt-2',
            componentId: 'showAllHiddenAddresses_' + defaultProductId,
            testId: 'showAllHiddenAddresses',
            componentType: 'ButtonComponent',
            containerId: 'gridCurrentAddressDetails_' + defaultProductId + '_col-6',
            isPublicEvent: false,
            level: 9,
            onClickEvent: 'bss-step-navigation-show-hidden-container',
            order: 7,
            productId: [defaultProductId, 'general'],
            sendEventToServer: false,
            text: "<value data-text='general.address_details_show_more'></value>",
            watcher: [
                {
                    boundDataOperand: 'operandLeft',
                    checkFlat: true,
                    effectSuccess: 'show',
                    effectFail: 'hide',
                    operandLeft: defaultProductId +
                        '.components_textInputComponents_postcode2_value',
                    operator: 'is',
                },
            ],
        },
        {
            componentId: 'componentAddressDetail_2_Information_' + defaultProductId,
            testId: 'componentAddressDetail_2_Information',
            componentType: 'TemplateComponent',
            componentTemplate: 'componentContainer',
            containerId: 'navigationTabsOnlineApproval_tab-content-5',
            level: 7,
            order: 4,
            productId: [defaultProductId, 'general'],
            isParentDependentChild: true,
            watcher: [
                {
                    boundDataOperand: 'operandLeft',
                    checkFlat: true,
                    effectSuccess: 'show',
                    effectFail: 'hide',
                    operandLeft: defaultProductId +
                        '.components_textInputComponents_postcode2_value',
                    operator: 'is',
                },
            ],
            class: 'bss-component-container-wrapper--step-navigation bss-mb-5 showAllHiddenAddresses oa-hidden-xs',
        },
        {
            componentId: 'gridCurrentAddressDetailsHeader_2_' + defaultProductId,
            testId: 'gridCurrentAddressDetailsHeader_2',
            componentType: 'TemplateComponent',
            containerId: 'componentAddressDetail_2_Information_' + defaultProductId + '_content',
            productId: [defaultProductId, 'general'],
            level: 7,
            order: 5,
            componentTemplate: 'grid',
            columns: ['bss-col-xs-8', 'bss-col-xs-4'],
        },
        {
            componentId: 'summaryAddressDetailsHeader_2_' + defaultProductId,
            testId: 'summaryAddressDetailsHeader_2',
            containerId: 'gridCurrentAddressDetailsHeader_2_' + defaultProductId + '_col-0',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 8,
            order: 1,
            text: "<value data-text='general.previous_address_details'></value>",
            class: 'bss-mb-2 bss-label--step-navigation-small-title',
        },
        {
            componentId: 'backAddressDetailsViewButton_2_' + defaultProductId,
            testId: 'backAddressDetailsViewButton_2',
            class: 'bss-button_btn--close bss-button_btn--edit bss-link-wrapper--uppercase bss-link-wrapper--no-sides bss-link-wrapper--pulled-right',
            componentType: 'ButtonComponent',
            componentTemplate: 'link',
            containerId: 'gridCurrentAddressDetailsHeader_2_' + defaultProductId + '_col-1',
            isPublicEvent: false,
            level: 8,
            onClickEvent: 'bss-step-navigation-goToStep-1-navigationTabsOnlineApproval',
            text: "<value data-text='general.back_to_edit'></value>",
            order: 2,
            productId: [defaultProductId, 'general'],
            sendEventToServer: false,
        },
        {
            componentId: 'gridCurrentAddressDetails_2_' + defaultProductId,
            testId: 'gridCurrentAddressDetails_2',
            componentType: 'TemplateComponent',
            containerId: 'componentAddressDetail_2_Information_' + defaultProductId + '_content',
            productId: [defaultProductId, 'general'],
            level: 8,
            order: 3,
            componentTemplate: 'grid',
            columns: [
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
            ],
        },
        {
            componentId: 'gridCurrentAddressDetails_2_Label2_' + defaultProductId,
            testId: 'gridCurrentAddressDetails_2_Label2',
            containerId: 'gridCurrentAddressDetails_2_' + defaultProductId + '_col-0',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 1,
            text: "<value data-text='general.address1'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.address2AddressLine1.value'></value>",
            class: 'bss-label--step-navigation-description bss-label--step-navigation-description-bordered bss-label--step-navigation-table',
        },
        {
            componentId: 'gridCurrentAddressDetails_2_Label1_' + defaultProductId,
            testId: 'gridCurrentAddressDetails_2_Label1',
            containerId: 'gridCurrentAddressDetails_2_' + defaultProductId + '_col-1',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 2,
            text: "<value data-text='general.address2'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.address2AddressLine2.value'></value>",
            class: 'bss-label--step-navigation-description bss-label--step-navigation-description-bordered bss-label--step-navigation-table',
        },
        {
            componentId: 'gridCurrentAddressDetails_2_Label3_' + defaultProductId,
            testId: 'gridCurrentAddressDetails_2_Label3',
            containerId: 'gridCurrentAddressDetails_2_' + defaultProductId + '_col-2',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 3,
            text: "<value data-text='general.postcode'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.postcode2.value'></value>",
            class: 'bss-label--step-navigation-description bss-label--step-navigation-description-bordered bss-label--step-navigation-table',
        },
        {
            componentId: 'gridCurrentAddressDetails_2_Label4_' + defaultProductId,
            testId: 'gridCurrentAddressDetails_2_Label4',
            containerId: 'gridCurrentAddressDetails_2_' + defaultProductId + '_col-3',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 4,
            text: "<value data-text='general.city'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.city2.value'></value>",
            class: 'bss-label--step-navigation-description bss-label--step-navigation-description-bordered bss-label--step-navigation-table',
        },
        {
            componentId: 'gridCurrentAddressDetails_2_Label5_' + defaultProductId,
            testId: 'gridCurrentAddressDetails_2_Label5',
            containerId: 'gridCurrentAddressDetails_2_' + defaultProductId + '_col-4',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 5,
            text: "<value data-text='general.county'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.county2.value'></value>",
            class: 'bss-label--step-navigation-description bss-label--step-navigation-description-bordered bss-label--step-navigation-table',
            watcher: [
                {
                    boundDataOperand: 'operandLeft',
                    checkFlat: true,
                    effectSuccess: 'show',
                    effectFail: 'hide',
                    operandLeft: defaultProductId + '.components_textInputComponents_county2_value',
                    operator: 'is',
                },
            ],
        },
        {
            componentId: 'gridCurrentAddressDetails_2_Label6_' + defaultProductId,
            testId: 'gridCurrentAddressDetails_2_Label6',
            containerId: 'gridCurrentAddressDetails_2_' + defaultProductId + '_col-5',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 6,
            text: "<value data-value='" +
                defaultProductId +
                ".components.labelComponents.address_2_occupation_duration.value'></value>",
            class: 'bss-label--step-navigation-description bss-label--step-navigation-description-bordered bss-label--step-navigation-table bss-label--step-navigation-bottom-info',
            watcher: [
                {
                    boundDataOperand: 'operandLeft',
                    checkFlat: true,
                    effectSuccess: 'show',
                    effectFail: 'hide',
                    operandLeft: defaultProductId +
                        '.components_labelComponents_address_2_occupation_duration_value',
                    operator: 'is',
                },
            ],
        },
        {
            componentId: 'componentAddressDetail_3_Information_' + defaultProductId,
            testId: 'componentAddressDetail_3_Information',
            componentType: 'TemplateComponent',
            componentTemplate: 'componentContainer',
            containerId: 'navigationTabsOnlineApproval_tab-content-5',
            level: 7,
            order: 5,
            productId: [defaultProductId, 'general'],
            isParentDependentChild: true,
            watcher: [
                {
                    boundDataOperand: 'operandLeft',
                    checkFlat: true,
                    effectSuccess: 'show',
                    effectFail: 'hide',
                    operandLeft: defaultProductId +
                        '.components_textInputComponents_postcode3_value',
                    operator: 'is',
                },
            ],
            class: 'bss-component-container-wrapper--step-navigation bss-mb-5 showAllHiddenAddresses oa-hidden-xs',
        },
        {
            componentId: 'gridCurrentAddressDetailsHeader_3_' + defaultProductId,
            testId: 'gridCurrentAddressDetailsHeader_3',
            componentType: 'TemplateComponent',
            containerId: 'componentAddressDetail_3_Information_' + defaultProductId + '_content',
            productId: [defaultProductId, 'general'],
            level: 7,
            order: 6,
            componentTemplate: 'grid',
            columns: ['bss-col-xs-8', 'bss-col-xs-4'],
        },
        {
            componentId: 'summaryAddressDetails_3_' + defaultProductId,
            testId: 'summaryAddressDetails_3',
            containerId: 'gridCurrentAddressDetailsHeader_3_' + defaultProductId + '_col-0',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 8,
            order: 1,
            text: "<value data-text='general.previous_address_details'></value>",
            class: 'bss-mb-2 bss-label--step-navigation-small-title',
        },
        {
            componentId: 'backAddressDetailsViewButton_3_' + defaultProductId,
            testId: 'backAddressDetailsViewButton_3',
            class: 'bss-button_btn--close bss-button_btn--edit bss-link-wrapper--uppercase bss-link-wrapper--no-sides bss-link-wrapper--pulled-right',
            componentType: 'ButtonComponent',
            componentTemplate: 'link',
            containerId: 'gridCurrentAddressDetailsHeader_3_' + defaultProductId + '_col-1',
            isPublicEvent: false,
            level: 8,
            onClickEvent: 'bss-step-navigation-goToStep-1-navigationTabsOnlineApproval',
            text: "<value data-text='general.back_to_edit'></value>",
            order: 2,
            productId: [defaultProductId, 'general'],
            sendEventToServer: false,
        },
        {
            componentId: 'gridCurrentAddressDetails_3_' + defaultProductId,
            testId: 'gridCurrentAddressDetails_3',
            componentType: 'TemplateComponent',
            containerId: 'componentAddressDetail_3_Information_' + defaultProductId + '_content',
            productId: [defaultProductId, 'general'],
            level: 8,
            order: 3,
            componentTemplate: 'grid',
            columns: [
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
            ],
        },
        {
            componentId: 'gridCurrentAddressDetails_3_Label2_' + defaultProductId,
            testId: 'gridCurrentAddressDetails_3_Label2',
            containerId: 'gridCurrentAddressDetails_3_' + defaultProductId + '_col-0',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 1,
            text: "<value data-text='general.address1'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.address3AddressLine1.value'></value>",
            class: 'bss-label--step-navigation-description bss-label--step-navigation-description-bordered bss-label--step-navigation-table',
        },
        {
            componentId: 'gridCurrentAddressDetails_3_Label1_' + defaultProductId,
            testId: 'gridCurrentAddressDetails_3_Label1',
            containerId: 'gridCurrentAddressDetails_3_' + defaultProductId + '_col-1',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 2,
            text: "<value data-text='general.address2'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.address3AddressLine2.value'></value>",
            class: 'bss-label--step-navigation-description bss-label--step-navigation-description-bordered bss-label--step-navigation-table',
        },
        {
            componentId: 'gridCurrentAddressDetails_3_Label3_' + defaultProductId,
            testId: 'gridCurrentAddressDetails_3_Label3',
            containerId: 'gridCurrentAddressDetails_3_' + defaultProductId + '_col-2',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 3,
            text: "<value data-text='general.postcode'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.postcode3.value'></value>",
            class: 'bss-label--step-navigation-description bss-label--step-navigation-description-bordered bss-label--step-navigation-table',
        },
        {
            componentId: 'gridCurrentAddressDetails_3_Label4_' + defaultProductId,
            testId: 'gridCurrentAddressDetails_3_Label4',
            containerId: 'gridCurrentAddressDetails_3_' + defaultProductId + '_col-3',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 4,
            text: "<value data-text='general.city'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.city3.value'></value>",
            class: 'bss-label--step-navigation-description bss-label--step-navigation-description-bordered bss-label--step-navigation-table',
        },
        {
            componentId: 'gridCurrentAddressDetails_3_Label5_' + defaultProductId,
            testId: 'gridCurrentAddressDetails_3_Label5',
            containerId: 'gridCurrentAddressDetails_3_' + defaultProductId + '_col-4',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 5,
            text: "<value data-text='general.county'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.county3.value'></value>",
            class: 'bss-label--step-navigation-description bss-label--step-navigation-description-bordered bss-label--step-navigation-table',
            watcher: [
                {
                    boundDataOperand: 'operandLeft',
                    checkFlat: true,
                    effectSuccess: 'show',
                    effectFail: 'hide',
                    operandLeft: defaultProductId + '.components.textInputComponents_county3_value',
                    operator: 'is',
                },
            ],
        },
        {
            componentId: 'gridCurrentAddressDetails_3_Label6_' + defaultProductId,
            testId: 'gridCurrentAddressDetails_3_Label6',
            containerId: 'gridCurrentAddressDetails_3_' + defaultProductId + '_col-5',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 6,
            text: "<value data-value='" +
                defaultProductId +
                ".components.labelComponents.address_3_occupation_duration.value'></value>",
            class: 'bss-label--step-navigation-description bss-label--step-navigation-description-bordered bss-label--step-navigation-table bss-label--step-navigation-bottom-info',
            watcher: [
                {
                    boundDataOperand: 'operandLeft',
                    checkFlat: true,
                    effectSuccess: 'show',
                    effectFail: 'hide',
                    operandLeft: defaultProductId +
                        '.components_labelComponents_address_3_occupation_duration_value',
                    operator: 'is',
                },
            ],
        },
        {
            componentId: 'componentEmploymentDetails_Information_' + defaultProductId,
            testId: 'componentEmploymentDetails_Information',
            componentType: 'TemplateComponent',
            componentTemplate: 'componentContainer',
            containerId: 'navigationTabsOnlineApproval_tab-content-5',
            level: 7,
            order: 6,
            productId: [defaultProductId, 'general'],
            isParentDependentChild: true,
            watcher: [
                {
                    boundDataOperand: 'operandLeft',
                    checkFlat: true,
                    effectSuccess: 'show',
                    effectFail: 'hide',
                    operandLeft: defaultProductId +
                        '.components_dropdownComponents_employmentType_value',
                    operator: 'is',
                },
            ],
            class: 'bss-component-container-wrapper--step-navigation bss-mb-5',
        },
        {
            componentId: 'gridEmploymentDetailsHeader_' + defaultProductId,
            testId: 'gridEmploymentDetailsHeader',
            componentType: 'TemplateComponent',
            containerId: 'componentEmploymentDetails_Information_' +
                defaultProductId +
                '_content',
            productId: [defaultProductId, 'general'],
            level: 7,
            order: 7,
            componentTemplate: 'grid',
            columns: ['bss-col-xs-8', 'bss-col-xs-4'],
        },
        {
            componentId: 'summaryEmploymentDetails_' + defaultProductId,
            testId: 'summaryEmploymentDetails',
            containerId: 'gridEmploymentDetailsHeader_' + defaultProductId + '_col-0',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 8,
            order: 1,
            text: "<value data-text='general.current_employment_details'></value>",
            class: 'bss-label--step-navigation-title bss-m-0',
        },
        {
            componentId: 'backEmploymentDetailsViewButton_' + defaultProductId,
            testId: 'backEmploymentDetailsViewButton',
            class: 'bss-button_btn--close bss-button_btn--edit bss-link-wrapper--uppercase bss-link-wrapper--no-sides bss-link-wrapper--pulled-right',
            componentType: 'ButtonComponent',
            componentTemplate: 'link',
            containerId: 'gridEmploymentDetailsHeader_' + defaultProductId + '_col-1',
            isPublicEvent: false,
            level: 8,
            onClickEvent: 'bss-step-navigation-goToStep-2-navigationTabsOnlineApproval',
            text: "<value data-text='general.back_to_edit'></value>",
            order: 2,
            productId: [defaultProductId, 'general'],
            sendEventToServer: false,
        },
        {
            componentId: 'gridEmploymentDetails_' + defaultProductId,
            testId: 'gridEmploymentDetails',
            componentType: 'TemplateComponent',
            containerId: 'componentEmploymentDetails_Information_' +
                defaultProductId +
                '_content',
            productId: [defaultProductId, 'general'],
            level: 8,
            order: 3,
            componentTemplate: 'grid',
            columns: ['bss-col-xs-12', 'bss-col-xs-12', 'bss-col-xs-12'],
        },
        {
            componentId: 'gridEmploymentDetails_Label-info_' + defaultProductId,
            testId: 'gridEmploymentDetails_Label-info',
            containerId: 'gridEmploymentDetails_' + defaultProductId + '_col-0',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 1,
            text: "<value data-text='general.employment_type'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.dropdownComponents.employmentType.value'></value>",
            class: 'bss-label--step-navigation-description bss-label--step-navigation-description-bordered bss-label--step-navigation-table',
            watcher: [
                {
                    boundDataOperand: 'operandLeft',
                    checkFlat: true,
                    effectSuccess: 'show',
                    effectFail: 'hide',
                    operandLeft: defaultProductId +
                        '.components_dropdownComponents_employmentType_value',
                    operandRight: ['unemployed', 'retired', 'student'],
                    operator: 'indexOf',
                },
            ],
        },
        {
            componentId: 'gridEmploymentDetailsInfo_' + defaultProductId,
            testId: 'gridEmploymentDetailsInfo',
            componentType: 'TemplateComponent',
            containerId: 'gridEmploymentDetails_' + defaultProductId + '_col-1',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 2,
            componentTemplate: 'grid',
            columns: [
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12 ',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
            ],
            class: 'bss-grid-no-padding',
            isParentDependentChild: true,
            watcher: [
                {
                    boundDataOperand: 'operandLeft',
                    checkFlat: true,
                    effectSuccess: 'hide',
                    effectFail: 'show',
                    operandLeft: defaultProductId +
                        '.components_dropdownComponents_employmentType_value',
                    operandRight: ['unemployed', 'retired', 'student'],
                    operator: 'indexOf',
                },
            ],
        },
        {
            componentId: 'gridEmploymentDetails_Label1_' + defaultProductId,
            testId: 'gridEmploymentDetails_Label1',
            containerId: 'gridEmploymentDetailsInfo_' + defaultProductId + '_col-0',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 10,
            order: 1,
            text: "<value data-text='general.employment_type'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.dropdownComponents.employmentType.value'></value>",
            class: 'bss-label--step-navigation-description bss-label--step-navigation-description-bordered bss-label--step-navigation-table',
        },
        {
            componentId: 'gridEmploymentDetails_Label2_' + defaultProductId,
            testId: 'gridEmploymentDetails_Label2',
            containerId: 'gridEmploymentDetailsInfo_' + defaultProductId + '_col-1',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 10,
            order: 2,
            text: "<value data-text='general.employment_status'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.dropdownComponents.employmentStatus.value'></value>",
            class: 'bss-label--step-navigation-description bss-label--step-navigation-description-bordered bss-label--step-navigation-table',
        },
        {
            componentId: 'gridEmploymentDetails_Label3_' + defaultProductId,
            testId: 'gridEmploymentDetails_Label3',
            containerId: 'gridEmploymentDetailsInfo_' + defaultProductId + '_col-2',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 10,
            order: 3,
            text: "<value data-text='general.occupation'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.dropdownComponents.occupation.value'></value>",
            class: 'bss-label--step-navigation-description bss-label--step-navigation-description-bordered bss-label--step-navigation-table',
        },
        {
            componentId: 'gridEmploymentDetails_Label4_' + defaultProductId,
            testId: 'gridEmploymentDetails_Label4',
            containerId: 'gridEmploymentDetailsInfo_' + defaultProductId + '_col-3',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 10,
            order: 4,
            text: "<value data-text='general.job_title'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.jobTitle.value'></value>",
            class: 'bss-label--step-navigation-description bss-label--step-navigation-description-bordered bss-label--step-navigation-table',
        },
        {
            componentId: 'gridEmploymentDetails_Label5_' + defaultProductId,
            testId: 'gridEmploymentDetails_Label5',
            containerId: 'gridEmploymentDetailsInfo_' + defaultProductId + '_col-4',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 10,
            order: 5,
            text: "<value data-text='general.employer'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.employer.value'></value>",
            class: 'bss-label--step-navigation-description bss-label--step-navigation-description-bordered bss-label--step-navigation-table',
        },
        {
            componentId: 'gridEmploymentDetails_Label6_' + defaultProductId,
            testId: 'gridEmploymentDetails_Label6',
            containerId: 'gridEmploymentDetailsInfo_' + defaultProductId + '_col-5',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 10,
            order: 6,
            text: "<value data-text='general.employment_postcode'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.employmentPostCode.value'></value>",
            class: 'bss-label--step-navigation-description bss-label--step-navigation-description-bordered bss-label--step-navigation-table',
        },
        {
            componentId: 'gridEmploymentDetails_Label8_' + defaultProductId,
            testId: 'gridEmploymentDetails_Label8',
            containerId: 'gridEmploymentDetailsInfo_' + defaultProductId + '_col-6',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 10,
            order: 7,
            text: "<value data-text='general.address1'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.employmentAddress1AddressLine1.value'></value>",
            class: 'bss-label--step-navigation-description bss-label--step-navigation-description-bordered bss-label--step-navigation-table',
        },
        {
            componentId: 'gridEmploymentDetails_Label7_' + defaultProductId,
            testId: 'gridEmploymentDetails_Label7',
            containerId: 'gridEmploymentDetailsInfo_' + defaultProductId + '_col-7',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 10,
            order: 8,
            text: "<value data-text='general.address2'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.employmentAddress1AddressLine2.value'></value>",
            class: 'bss-label--step-navigation-description bss-label--step-navigation-description-bordered bss-label--step-navigation-table',
        },
        {
            componentId: 'gridEmploymentDetails_Label9_' + defaultProductId,
            testId: 'gridEmploymentDetails_Label9',
            containerId: 'gridEmploymentDetailsInfo_' + defaultProductId + '_col-8',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 10,
            order: 9,
            text: "<value data-text='general.employment_town'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.employmentTown.value'></value>",
            class: 'bss-label--step-navigation-description bss-label--step-navigation-description-bordered bss-label--step-navigation-table',
        },
        {
            componentId: 'gridEmploymentDetails_Label10_' + defaultProductId,
            testId: 'gridEmploymentDetails_Label10',
            containerId: 'gridEmploymentDetailsInfo_' + defaultProductId + '_col-9',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 10,
            order: 10,
            text: "<value data-text='general.employment_county'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.employmentCounty.value'></value>",
            class: 'bss-label--step-navigation-description bss-label--step-navigation-description-bordered bss-label--step-navigation-table',
            watcher: [
                {
                    boundDataOperand: 'operandLeft',
                    checkFlat: true,
                    effectSuccess: 'show',
                    effectFail: 'hide',
                    operandLeft: defaultProductId +
                        '.components_textInputComponents_employmentCounty_value',
                    operator: 'is',
                },
            ],
        },
        {
            componentId: 'gridEmploymentDetails_Label11_' + defaultProductId,
            testId: 'gridEmploymentDetails_Label11',
            containerId: 'gridEmploymentDetailsInfo_' + defaultProductId + '_col-10',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 10,
            order: 11,
            text: "<value data-value='" +
                defaultProductId +
                ".components.labelComponents.employment_1_duration.value'></value>",
            class: 'bss-label--step-navigation-description bss-label--step-navigation-description-bordered bss-label--step-navigation-table bss-label--step-navigation-bottom-info',
            watcher: [
                {
                    boundDataOperand: 'operandLeft',
                    checkFlat: true,
                    effectSuccess: 'show',
                    effectFail: 'hide',
                    operandLeft: defaultProductId +
                        '.components_labelComponents_employment_1_duration_value',
                    operator: 'is',
                },
            ],
        },
        {
            class: 'bss-button_btn--show-hidden bss-hide-hidden-employment bss-button-wrapper--pulled-left bss-button_btn--secondary bss-mt-2',
            componentId: 'showAllHiddenEmploymentDetails_' + defaultProductId,
            testId: 'showAllHiddenEmploymentDetails',
            componentType: 'ButtonComponent',
            containerId: 'gridEmploymentDetails_' + defaultProductId + '_col-2',
            isPublicEvent: false,
            level: 9,
            onClickEvent: 'bss-step-navigation-show-hidden-container',
            order: 3,
            productId: [defaultProductId, 'general'],
            sendEventToServer: false,
            text: "<value data-text='general.employment_details_show_more'></value>",
            watcher: [
                {
                    boundDataOperand: 'operandLeft',
                    checkFlat: true,
                    effectSuccess: 'show',
                    effectFail: 'hide',
                    operandLeft: defaultProductId +
                        '.components_dropdownComponents_employmentType2_value',
                    operator: 'is',
                },
            ],
        },
        {
            componentId: 'componentEmploymentDetails_Information_2_' + defaultProductId,
            componentType: 'TemplateComponent',
            componentTemplate: 'componentContainer',
            containerId: 'navigationTabsOnlineApproval_tab-content-5',
            level: 7,
            order: 7,
            productId: [defaultProductId, 'general'],
            isParentDependentChild: true,
            watcher: [
                {
                    boundDataOperand: 'operandLeft',
                    checkFlat: true,
                    effectSuccess: 'show',
                    effectFail: 'hide',
                    operandLeft: defaultProductId +
                        '.components_dropdownComponents_employmentType2_value',
                    operator: 'is',
                },
            ],
            class: 'bss-component-container-wrapper--step-navigation bss-mb-5 showAllHiddenEmploymentDetails oa-hidden-xs',
        },
        {
            componentId: 'gridEmploymentDetailsHeader_2_' + defaultProductId,
            testId: 'gridEmploymentDetailsHeader_2',
            componentType: 'TemplateComponent',
            containerId: 'componentEmploymentDetails_Information_2_' +
                defaultProductId +
                '_content',
            productId: [defaultProductId, 'general'],
            level: 8,
            order: 3,
            componentTemplate: 'grid',
            columns: ['bss-col-xs-8', 'bss-col-xs-4'],
        },
        {
            componentId: 'summaryEmploymentDetails_2_' + defaultProductId,
            testId: 'summaryEmploymentDetails_2',
            containerId: 'gridEmploymentDetailsHeader_2_' + defaultProductId + '_col-0',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 1,
            text: "<value data-text='general.previous_employment_details'></value>",
            class: 'bss-mb-2 bss-label--step-navigation-small-title',
        },
        {
            componentId: 'backEmploymentDetailsViewButton_2_' + defaultProductId,
            testId: 'backEmploymentDetailsViewButton_2',
            class: 'bss-button_btn--close bss-button_btn--edit bss-link-wrapper--uppercase bss-link-wrapper--no-sides bss-link-wrapper--pulled-right',
            componentType: 'ButtonComponent',
            componentTemplate: 'link',
            containerId: 'gridEmploymentDetailsHeader_2_' + defaultProductId + '_col-1',
            isPublicEvent: false,
            level: 9,
            onClickEvent: 'bss-step-navigation-goToStep-2-navigationTabsOnlineApproval',
            text: "<value data-text='general.back_to_edit'></value>",
            order: 2,
            productId: [defaultProductId, 'general'],
            sendEventToServer: false,
        },
        {
            componentId: 'gridEmploymentDetails_2_' + defaultProductId,
            testId: 'gridEmploymentDetails_2',
            componentType: 'TemplateComponent',
            containerId: 'componentEmploymentDetails_Information_2_' +
                defaultProductId +
                '_content',
            productId: [defaultProductId, 'general'],
            level: 8,
            order: 3,
            componentTemplate: 'grid',
            columns: ['bss-col-xs-12', 'bss-col-xs-12'],
        },
        {
            componentId: 'gridEmploymentDetails_2_Label_info_' + defaultProductId,
            testId: 'gridEmploymentDetails_2_Label_info',
            containerId: 'gridEmploymentDetails_2_' + defaultProductId + '_col-0',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 1,
            text: "<value data-text='general.employment_type'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.dropdownComponents.employmentType2.value'></value>",
            class: 'bss-label--step-navigation-description bss-label--step-navigation-description-bordered bss-label--step-navigation-table',
            watcher: [
                {
                    boundDataOperand: 'operandLeft',
                    checkFlat: true,
                    effectSuccess: 'show',
                    effectFail: 'hide',
                    operandLeft: defaultProductId +
                        '.components_dropdownComponents_employmentType2_value',
                    operandRight: ['unemployed', 'retired', 'student'],
                    operator: 'indexOf',
                },
            ],
        },
        {
            componentId: 'gridEmploymentDetails_2_Info_' + defaultProductId,
            testId: 'gridEmploymentDetails_2_Info',
            componentType: 'TemplateComponent',
            containerId: 'gridEmploymentDetails_2_' + defaultProductId + '_col-1',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 2,
            componentTemplate: 'grid',
            columns: [
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
            ],
            class: 'bss-grid-no-padding',
            isParentDependentChild: true,
            watcher: [
                {
                    boundDataOperand: 'operandLeft',
                    checkFlat: true,
                    effectSuccess: 'hide',
                    effectFail: 'show',
                    operandLeft: defaultProductId +
                        '.components_dropdownComponents_employmentType2_value',
                    operandRight: ['unemployed', 'retired', 'student'],
                    operator: 'indexOf',
                },
            ],
        },
        {
            componentId: 'gridEmploymentDetails_2_Label1_' + defaultProductId,
            testId: 'gridEmploymentDetails_2_Label1',
            containerId: 'gridEmploymentDetails_2_Info_' + defaultProductId + '_col-0',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 10,
            order: 1,
            text: "<value data-text='general.employment_type'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.dropdownComponents.employmentType2.value'></value>",
            class: 'bss-label--step-navigation-description bss-label--step-navigation-description-bordered bss-label--step-navigation-table',
        },
        {
            componentId: 'gridEmploymentDetails_2_Label2_' + defaultProductId,
            testId: 'gridEmploymentDetails_2_Label2',
            containerId: 'gridEmploymentDetails_2_Info_' + defaultProductId + '_col-1',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 10,
            order: 2,
            text: "<value data-text='general.employment_status'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.dropdownComponents.employmentStatus2.value'></value>",
            class: 'bss-label--step-navigation-description bss-label--step-navigation-description-bordered bss-label--step-navigation-table',
        },
        {
            componentId: 'gridEmploymentDetails_2_Label3_' + defaultProductId,
            testId: 'gridEmploymentDetails_2_Label3',
            containerId: 'gridEmploymentDetails_2_Info_' + defaultProductId + '_col-2',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 10,
            order: 3,
            text: "<value data-text='general.occupation'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.dropdownComponents.occupation2.value'></value>",
            class: 'bss-label--step-navigation-description bss-label--step-navigation-description-bordered bss-label--step-navigation-table',
        },
        {
            componentId: 'gridEmploymentDetails_2_Label4_' + defaultProductId,
            testId: 'gridEmploymentDetails_2_Label4',
            containerId: 'gridEmploymentDetails_2_Info_' + defaultProductId + '_col-3',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 10,
            order: 4,
            text: "<value data-text='general.job_title'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.jobTitle2.value'></value>",
            class: 'bss-label--step-navigation-description bss-label--step-navigation-description-bordered bss-label--step-navigation-table',
        },
        {
            componentId: 'gridEmploymentDetails_2_Label5_' + defaultProductId,
            testId: 'gridEmploymentDetails_2_Label5',
            containerId: 'gridEmploymentDetails_2_Info_' + defaultProductId + '_col-4',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 10,
            order: 5,
            text: "<value data-text='general.employer'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.employer2.value'></value>",
            class: 'bss-label--step-navigation-description bss-label--step-navigation-description-bordered bss-label--step-navigation-table',
        },
        {
            componentId: 'gridEmploymentDetails_2_Label6_' + defaultProductId,
            testId: 'gridEmploymentDetails_2_Label6',
            containerId: 'gridEmploymentDetails_2_Info_' + defaultProductId + '_col-5',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 10,
            order: 6,
            text: "<value data-text='general.employment_postcode'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.employmentPostCode2.value'></value>",
            class: 'bss-label--step-navigation-description bss-label--step-navigation-description-bordered bss-label--step-navigation-table',
        },
        {
            componentId: 'gridEmploymentDetails_2_Label7_' + defaultProductId,
            testId: 'gridEmploymentDetails_2_Label7',
            containerId: 'gridEmploymentDetails_2_Info_' + defaultProductId + '_col-6',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 10,
            order: 7,
            text: "<value data-text='general.address1'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.employmentAddress2AddressLine1.value'></value>",
            class: 'bss-label--step-navigation-description bss-label--step-navigation-description-bordered bss-label--step-navigation-table',
        },
        {
            componentId: 'gridEmploymentDetails_2_Label8_' + defaultProductId,
            testId: 'gridEmploymentDetails_2_Label8',
            containerId: 'gridEmploymentDetails_2_Info_' + defaultProductId + '_col-7',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 10,
            order: 8,
            text: "<value data-text='general.address2'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.employmentAddress2AddressLine2.value'></value>",
            class: 'bss-label--step-navigation-description bss-label--step-navigation-description-bordered bss-label--step-navigation-table',
        },
        {
            componentId: 'gridEmploymentDetails_2_Label9_' + defaultProductId,
            testId: 'gridEmploymentDetails_2_Label9',
            containerId: 'gridEmploymentDetails_2_Info_' + defaultProductId + '_col-8',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 10,
            order: 9,
            text: "<value data-text='general.employment_town'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.employmentTown2.value'></value>",
            class: 'bss-label--step-navigation-description bss-label--step-navigation-description-bordered bss-label--step-navigation-table',
        },
        {
            componentId: 'gridEmploymentDetails_2_Label10_' + defaultProductId,
            testId: 'gridEmploymentDetails_2_Label10',
            containerId: 'gridEmploymentDetails_2_Info_' + defaultProductId + '_col-9',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 10,
            order: 10,
            text: "<value data-text='general.employment_county'></value>",
            value: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.employmentCounty2.value'></value>",
            class: 'bss-label--step-navigation-description bss-label--step-navigation-description-bordered bss-label--step-navigation-table',
            watcher: [
                {
                    boundDataOperand: 'operandLeft',
                    checkFlat: true,
                    effectSuccess: 'show',
                    effectFail: 'hide',
                    operandLeft: defaultProductId +
                        '.components_textInputComponents_employmentCounty2_value',
                    operator: 'is',
                },
            ],
        },
        {
            componentId: 'gridEmploymentDetails_2_Label11_' + defaultProductId,
            testId: 'gridEmploymentDetails_2_Label11',
            containerId: 'gridEmploymentDetails_2_Info_' + defaultProductId + '_col-10',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 10,
            order: 11,
            text: "<value data-value='" +
                defaultProductId +
                ".components.labelComponents.employment_2_duration.value'></value>",
            class: 'bss-label--step-navigation-description bss-label--step-navigation-description-bordered bss-label--step-navigation-table bss-label--step-navigation-bottom-info',
            watcher: [
                {
                    boundDataOperand: 'operandLeft',
                    checkFlat: true,
                    effectSuccess: 'show',
                    effectFail: 'hide',
                    operandLeft: defaultProductId +
                        '.components_labelComponents_employment_2_duration_value',
                    operator: 'is',
                },
            ],
        },
    ];
}
